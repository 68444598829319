@use '../variables' as *;

@mixin skeleton() {
  background: linear-gradient(
    110deg,
    $color-base-purple-900 8%,
    $color-base-black-200 18%,
    $color-base-purple-900 33%
  );
  background-size: 400% 300%;
  animation: 5s shine linear infinite;
}
