@use 'src/src/app/scss/abstracts' as *;

.products-grid {
  $block-name: &;

  //   #{$block-name} {
  //   }

  &__title {
    margin-bottom: 70px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;

    @include mq($until: md) {
      margin-bottom: 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px 0;

    @include mq($until: lg) {
      gap: 15px 0;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;

    @include mq($until: lg) {
      gap: 0 15px;
    }

    @include mq($until: sm) {
      display: block;
    }

    &:nth-child(4n + 2) {
      flex-direction: row-reverse;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    gap: 20px 0;

    @include mq($until: lg) {
      width: calc(50% - 7.5px);
      gap: 15px 0;
    }

    @include mq($until: sm) {
      width: 100%;
      max-width: 100%;
    }

    &:first-child {
      @include mq($until: sm) {
        margin-bottom: 15px;
      }
    }
  }

  &__cell {
    .product-card--size-m {
      .image {
        margin-bottom: -10px;

        @include mq($until: lg) {
          margin-bottom: -7.5px;
        }
      }
    }
  }

  &--with-mobile-carousel {
    #{$block-name} {
      &__body {
        display: none;

        @include mq(sm) {
          display: block;
        }
      }

      &__carousel {
        display: block;

        @include mq(sm) {
          display: none;
        }
      }
    }
  }
}
