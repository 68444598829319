@use 'src/src/app/scss/abstracts' as *;

.product-card {
  $block-name: &;

  display: block;
  text-decoration: none;

  //   #{$block-name} {
  //   }

  &:hover {
    #{$block-name} {
      &__inner {
        transform: scale(0.98) translate3d(0, 0, 0);
      }

      &__text-box {
        transform: translate3d(0, -5px, 0);
      }

      &__price {
        background: $color-base-purple-900;
      }
    }
  }

  &:active {
    #{$block-name} {
      &__inner {
        transform: scale(0.97) translate3d(0, 0, 0);
      }
    }
  }

  &__inner {
    position: relative;
    transition: transform $transition-slow;
  }

  &__media {
    border-radius: $border-radius-s;
    overflow: hidden;

    @include mq($until: sm) {
      border-radius: $border-radius-2xs;
    }
  }

  &__price {
    display: inline-flex;
    color: rgba($color-semantic-third, 0.9);
    font-family: $font-family-headings;
    font-size: 36px;
    font-style: normal;
    font-weight: $font-weight-l;
    line-height: 110%;
    letter-spacing: -1.08px;
    padding: 5px 15px;
    border-radius: $border-radius-2xs;
    background: $color-base-black-200;
    transition: background-color $transition-slow;

    @include mq($until: md) {
      font-size: 18px;
      padding: 5px 8px;
    }
  }

  &__text-box {
    @include reset-text-indents;

    position: absolute;
    bottom: 0;
    left: 0;
    padding: 50px 40px;
    transform: translate3d(0, 0, 0);
    transition: transform $transition-slow;

    @include mq($until: '2xl') {
      padding: 30px 20px;
    }

    @include mq($until: md) {
      padding: 15px;
    }

    @include mq($until: sm) {
      padding: 10px;
    }
  }

  &__title {
    color: $color-base-purple-200;
    margin-top: 0;
    font-family: $font-family-headings;
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-m;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;
    margin-bottom: 15px;

    @include mq($until: md) {
      font-size: 20px;
      margin-bottom: 7px;
    }
  }

  &--loading {
    @include skeleton;

    border-radius: $border-radius-s;
    overflow: hidden;
    pointer-events: none;

    @include mq($until: sm) {
      border-radius: $border-radius-2xs;
    }

    #{$block-name} {
      &__media {
        opacity: 0;
      }

      &__text-box {
        opacity: 0;
      }
    }
  }

  &--size-xs {
    &:hover {
      #{$block-name} {
        &__inner {
          transform: none;
        }

        &__text-box {
          transform: none;
        }

        &__price {
          background: $color-base-purple-900;
        }
      }
    }

    #{$block-name} {
      &__inner {
      }

      &__text-box {
        position: static;
      }

      &__price {
        font-size: 14px;
        background-color: $color-base-purple-900;
      }

      &__title {
        @include ellipsis-multiline(16px, 1.2, 2);

        height: auto;
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }
}
