@charset "UTF-8";

/// Truncates text and adds an ellipsis to represent overflow.
///
/// @argument {number} $width [100%]
///   The `max-width` for the string to respect before being truncated.
///
/// @argument {string} $display [inline-block]
///   Sets the display-value of the element.
///
/// @example scss
///   .element {
///     @include ellipsis;
///   }
///
///   // CSS Output
///   .element {
///     display: inline-block;
///     max-width: 100%;
///     overflow: hidden;
///     text-overflow: ellipsis;
///     white-space: nowrap;
///     word-wrap: normal;
///   }

@mixin ellipsis($width: 100%, $display: inline-block) {
  display: $display;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

// p {
//   @include ellipsis-multiline($line-height: 1.4, $lines-to-show: 10);
// }

@mixin ellipsis-multiline($font-size: 1em, $line-height: 1.2, $lines-to-show) {
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  height: $font-size * $line-height * $lines-to-show;
  max-height: $font-size * $line-height * $lines-to-show; // Fallback for non-webkit
  overflow: hidden;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
